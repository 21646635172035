import React from 'react';
import { Button, Divider, InputGroup, Popover, Tooltip, Icon, IconSize } from "@blueprintjs/core";
import ActivityBarIcon from "../../components/ActivityBarIcon";
// import Logo from '../../images/logo.png';
import Logo from '../../images/logo.svg';
import  './activity-bar.css';
import EDialog from '../../components/EDialog';
import { useSelector, useDispatch } from 'react-redux';
import { makeAuthRequest } from '../../services/api';
import { attemptAuthentication, logOutOfApp } from '../session/session-actions';

const ActivtyBar = (props) => {
   const { contributions, } = props.params;
   const [showSearch, setShowSearch] = React.useState(false);
   const [search, setSearch] = React.useState('');
   const [dialogDetails, setDialogDetails] = React.useState(null);
   const { authenticated, authenticating, userDetails } = useSelector( state => state.session);
   const dispatch = useDispatch();

   let xLogin = {};

   const LOGIN_DIALOG = {
        title: 'Login',
        titleIcon: 'log-in',
        onClose : () => setDialogDetails(null),
        body: <div>
            <div style={{marginBottom: 10}}>
                <InputGroup type="text" placeholder="Username" icon='email'
                    onChange={(e) => {
                        xLogin['email'] = e.target.value;
                    }}
                />
            </div>
            <div style={{marginBottom: 10}}>
            <InputGroup type="password" placeholder="Username" icon='lock'
                    onChange={(e) => {
                        xLogin['password'] = e.target.value;
                    }}
            />
            </div>
        </div>,
        actions: [
        <Button text="Cancel" onClick={() => {
            console.log('Cancel');
            setDialogDetails(null);
        }} />,
        <Button text="Login" intent="primary" onClick={() => {
            dispatch(attemptAuthentication({
                email: xLogin['email'],
                password: xLogin['password']
            }));

            // await makeAuthRequest(xLogin['email'], xLogin['password']);

            //setDialogDetails(null);
        }} />
        ]
    };



    const LOGOUT_DIALOG = {
        title: 'Logout',
        titleIcon: 'log-out',
        onClose : () => setDialogDetails(null),
        body: <div>
            <div style={{marginBottom: 10}}>
                <p>Are you sure you want to logout?</p>
            </div>
        </div>,
        actions: [
        <Button text="Cancel" onClick={() => {
            console.log('Cancel');
            setDialogDetails(null);
        }} />,
        <Button text="Logout" intent="danger" onClick={() => {
            dispatch(logOutOfApp());
            setDialogDetails(null);
        }} />
        ]
    };


    // React.useState(() => {
    //     if(authenticating === false && authenticated === true) {
    //         setDialogDetails(null);
    //     }
    // }, [authenticating]);

    return (<div className="bp5-dark" style={{
        flexDirection: 'column', 
        alignSelf:'stretch', 
        height: '95vh',
        display: 'flex'}}>

        <div style={{flex:1, overflowY: 'scroll'}} className="activity-extensions">
            <div style={{margin: 15, marginBottom: 20}}>
                <Tooltip content="Home">
                    {/* <Icon icon="home" size={24} intent="primary" /> */}
                    <img src={Logo} alt="Logo" width={30} height={30} />
                </Tooltip>
            </div>
            <div style={{margin: 15, marginBottom: 20}} key="activityIcons">
                <Popover
                    content={<input type="search" 
                        placeholder="Search..." 
                        style={{width: 200, height: 30}}  
                        onChange={(e) => { 
                            setSearch(e.target.value);
                            if(globalThis.commands$['bss.aws:handle-command']) { 
                                globalThis.commands$['bss.aws:handle-command'].next(e.target.value);
                            }
                        }} value={search}/>}
                    // position="bottom"
                    placement="right"
                    isOpen={showSearch}
                    onClose={() => {
                        setShowSearch(false);
                    }}
                >
                <Tooltip content="Activity Bar Search">
                    <Icon icon={search ? "search-template" : "search"} size={24} intent="secondary" 
                        color={search ? "#2d72d2" : null}
                        onClick={() => {
                            setShowSearch(!showSearch);
                        }}
                    />
                </Tooltip>
                </Popover>
            </div>
            <div style={{margin: 15, marginBottom: 20}} key="extensions">
                <Tooltip content="Extensions">
                    <Icon icon="cube" size={24} intent="secondary" />
                </Tooltip>
            </div>
            {contributions.filter(v => {
            const p = new RegExp(search, 'gi');
            if (search === undefined) return true;
            if (search.length === 0) return true;
            if (p.test(v.name)) return true;
            return false;
            }).map((contribution, idx) => {
                return (<div style={{margin: 15, marginBottom: 20}} key={idx}>
                    <Tooltip content={contribution.label}>
                        <ActivityBarIcon icon={contribution.icon} size={24} onClick={() => { 
                            if(globalThis.commands$[contribution.id]) globalThis.commands$[contribution.id].next(contribution.name);
                        }}/>
                    </Tooltip>
                </div>);
            })}
        </div>
        
        <div>
            <Divider />
            
            <div style={{margin: 15}}>
                <Tooltip content={authenticated ? userDetails.first_name : 'Account'}> 
                    <Icon 
                        icon={authenticated ? 'user' : 'lock'} size={24} 
                        intent="secondary" 
                        onClick={() => {
                        if(authenticated) {
                            setDialogDetails(LOGOUT_DIALOG);
                        }else{
                            setDialogDetails(LOGIN_DIALOG);
                        }
                       
                    }}/>
                </Tooltip>    
            </div>
            
            <div style={{margin: 15}}>
                <Tooltip content="Settings"> 
                    <Icon icon="cog" size={24} intent="secondary" />
                </Tooltip>
            </div>
            
        </div>

        {dialogDetails && <EDialog {...dialogDetails} />}
    </div>);
}

export default ActivtyBar