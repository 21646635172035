import React from 'react';
import { Dialog, DialogBody, DialogFooter } from '@blueprintjs/core';

const  EDialog = ({title, titleIcon, onClose, body, actions, style, intent}) => {
    

return (
<Dialog 
    intent={intent}
    isOpen={true} 
    title={title} 
    icon={titleIcon} 
    onClose={() => typeof onClose == 'function' ? onClose() : null} 
    style={style}
    >
    <DialogBody>
        {body && <>{body}</>}
    </DialogBody>
    <DialogFooter actions={[
        <>{actions}</>
    ]} />
</Dialog>);
};

export default EDialog;