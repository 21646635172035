import React from 'react'
import logo from '../../images/logo.svg';
// import logo from '../../images/logo.png';
import { connect } from 'react-redux';
//import PropTypes from 'prop-types';
import  './LoginForm.css';
import Loading from './Loading';
import { logIntoApp, attemptAuthentication, clearAuthError, clearOldSession, checkDBSetupStatus } from '../session/session-actions';
import { Button, Intent, FormGroup, InputGroup } from "@blueprintjs/core";
import VERSION from '../../version';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';

class LoginForm extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            email: "expert@bodapro.app",
            password: "password"
        };
        
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.dismissError = this.dismissError.bind(this);
        this.handleLoginAdDifferentUser = this.handleLoginAdDifferentUser.bind(this);

    }
    
    componentDidMount(){
        //reload the login info from local storage 
        const loginInfo = localStorage.getItem('bodapro-user');
        if(loginInfo){
            const user = JSON.parse(loginInfo);
            this.props.dispatch(logIntoApp(user));
        }


        if(typeof this.props.userDetails !== 'undefined' && this.props.userDetails !== null ){
            this.setState({email: this.props.userDetails.email});
			return;
        }
        
        //Check if database is ready
        this.props.dispatch(checkDBSetupStatus());
        
    }
    
    handleLoginAdDifferentUser = () => {
		//console.log(e);
		//e.preventDefault();
        this.props.dispatch(clearOldSession())
    }
    
    dismissError(){
        this.props.dispatch(clearAuthError());
    }

    handleSubmit(event){
        event.preventDefault();

          this.props.dispatch(attemptAuthentication({
              email: this.state['email'],
              password: this.state['password']
          }));
        
    }

    handleInputChange(event){
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }
    
    render(){
            //The type of alert to use when the user fails to login. 
            //This can either be danger for a wrong passworg or info for database
            //setup pending
            let errorAlertType = this.props.waitingForDB === true? 'info' : 'danger';
            
            return (<div className="night">


            <div className="login-mask">
                <div class="shooting_star"></div>
                <div class="shooting_star"></div>
                <div class="shooting_star"></div>

                <div className="login-logo">
                    <img src={logo} width="100px" alt="BodaPro" /> 
                    <span>
                        <h2  className="bp3-heading bp3-ui-text">BodaPro</h2>
                        {/* <h6 className="bp3-heading">v{VERSION}</h6> */}
                    </span>
                </div>

                <div class="shooting_star"></div>
                <div class="shooting_star"></div>
                <div class="shooting_star"></div>
                
                <form className="form-signin" onSubmit={this.handleSubmit}>
                    <Loading show={this.props.authenticating}/>
                    
                    {this.props.loginError == null ? "" :
                        <div className={`alert alert-${errorAlertType} p-2`} role="alert">
                            {this.props.loginError}

                            <button type="button" className="btn-close right float-end" data-bs-dismiss="alert" aria-label="Close" onClick={this.dismissError}></button>
                        </div>
                    }
                    
                    {typeof this.props.userDetails === 'undefined' || this.props.userDetails === null? 
                        <React.Fragment>
                            <FormGroup
                                label=""
                                labelFor="session_email"
                            >
                                <InputGroup id="session_email" placeholder="Email" 
                                    required={true}
                                    leftIcon="user" 
                                    name="email"
                                    type="email"
                                    value={this.state.email}
                                    onChange={this.handleInputChange} />
                            </FormGroup>
                        </React.Fragment>
                    :''}
                    
                    {typeof this.props.userDetails === 'undefined' || this.props.userDetails === null? '' : 
                        <React.Fragment>
                            <label htmlFor="email" className="sr-only">email</label>
                            <div className="input-group">
                            <span className="font-weight-light">Login as </span>  &nbsp;
                                <span className="font-weight-bold">{this.props.userDetails.first_name + ", " +  this.props.userDetails.last_name}</span>
                            </div>
                            
                        </React.Fragment>     
                    }
                    
                    <div class="shooting_star"></div>
                <div class="shooting_star"></div>
                <div class="shooting_star"></div>
                    
                        <FormGroup
                            label=""
                            labelFor="session_password"

                        >
                            <InputGroup id="session_password" placeholder="Password" 
                                leftIcon="lock" 
                                name="password"
                                type="password"
                                required={true}
                                value={this.state.password}
                                onChange={this.handleInputChange} />
                        </FormGroup>


                        <div style={{flexDirection: 'row'}}>
                            <Button type="submit" text="Sign in" intent={Intent.PRIMARY} disabled={this.props.authenticating}/> &nbsp;
                            <span style={{textAlign: 'right', flex: 1}}>Create account</span>
                        </div>

                        <div style={{borderBottom: 1, borderBottomColor: '#eeeeee'}}></div>
                        {/* <div style={{flexDirection: 'row', marginTop: 10}}>
                            <FontAwesomeIcon icon={faGoogle} size="2xl" />
                            <span style={{marginRight: 5}}> </span>
                            <FontAwesomeIcon icon={faLinkedin} size="2xl"/>
                            <span style={{marginRight: 5}}> </span>
                            <FontAwesomeIcon icon={faGithub} size="2xl"/>
                        </div> */}
                        {/* <div style={{flexDirection: 'row', marginTop: 10}}>
                            <Button type="submit" text="Forgot password" intent={Intent.NONE} disabled={this.props.authenticating} fill/> &nbsp;
                        </div> */}

                    {typeof this.props.userDetails !== 'undefined' && this.props.userDetails !== null? 
                        <a href="/#" onClick={(e) => { e.preventDefault(); this.handleLoginAdDifferentUser();}}> as different user</a>
                    :''}
                </form>
                <div class="shooting_star"></div>
                <div class="shooting_star"></div>
                <div class="shooting_star"></div>

            </div>

            </div>
            );
    }
}

/*
LoginForm.propTypes = {
    authenticating: PropTypes.bool,
    loginError: PropTypes.string,
    userDetails: PropTypes.object,
    waitingForDB: PropTypes.string
};
*/

function mapStateToProps(state) {
  return {
    authenticating: state.session.authenticating,
    loginError: state.session.loginError,
    userDetails: state.session.userDetails,
    waitingForDB: state.session.waitingForDB
  }
}

export default connect(mapStateToProps)(LoginForm);